body {
  margin: 0;
  padding: 0;
  padding-top: 80px;
  width: 100%;
  height: auto;
  background: url(img/bg.jpg) top right no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}
body div.wrap {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
body div.clear {
  clear: both;
}
body img.body_image {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  margin-top: -4px;
}
body a {
  color: #fff;
  text-decoration: none;
  font-family: 'graublausans-bold';
}
body ul.body_image {
  overflow: auto;
  width: 100%;
}
body ul.body_image li.marken_image {
  position: relative;
  display: block;
  float: left;
  width: 40%;
  margin: 5%;
}
body ul.body_image li.marken_image img {
  max-width: 100%;
}
body ul.body_image li.marken_image img:hover {
  opacity: 0.8;
  cursor: pointer;
}
html {
  box-sizing: border-box;
}
#main_header {
  position: fixed;
  display: block;
  top: 0;
  width: 100%;
  height: 80px !important;
  background: #0D1E31;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.55);
  z-index: 200;
}
#main_header #menu-trigger {
  display: none;
}
#main_header nav {
  position: relative;
}
#main_header nav ul {
  list-style: none;
  color: #fff;
  font-family: 'graublausans-bold';
  margin-left: 8%;
}
#main_header nav ul li {
  float: left;
  margin-right: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  padding: 10px 20px;
  border: 2px solid transparent;
  font-size: 14px;
}
#main_header nav ul li#link_home img {
  width: 30px;
  height: auto;
  margin-top: -5px;
}
#main_header nav ul li#link_home.active {
  border: 2px solid transparent;
}
#main_header nav ul li#link_home:hover {
  border: 2px solid transparent;
}
#main_header nav ul li.active {
  border: 2px solid #fff;
}
#main_header nav ul li:hover {
  border: 2px solid #fff;
}
#main_header nav ul li a {
  color: #fff;
  text-decoration: none;
}
/* ------------------------- */
/*      MENU TOGGLE BUTTON
/* ------------------------- */
.toggle-button,
.mn2 {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.toggle-button .wolverine,
.mn2 .wolverine {
  display: inline-block;
  padding: 18px 10px;
  cursor: pointer;
  -webkit-user-select: none;
  opacity: .97;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.toggle-button .wolverine .claws,
.mn2 .wolverine .claws {
  position: relative;
  width: 25px;
  height: 3px;
  background: #fff;
}
.toggle-button .wolverine .claws:before,
.mn2 .wolverine .claws:before,
.toggle-button .wolverine .claws:after,
.mn2 .wolverine .claws:after {
  display: block;
  content: "";
  height: 3px;
  width: 25px;
  background: #fff;
  position: absolute;
  z-index: -1;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: top 0.1s 0.2s, -webkit-transform 0.2s ease;
  -webkit-transition-delay: ease,0s;
  -moz-transition: top 0.1s 0.2s ease, -moz-transform 0.2s ease;
  -o-transition: top 0.1s 0.2s ease, -o-transform 0.2s ease;
  transition: top .1s .2s ease,transform .2s ease;
}
.toggle-button .wolverine .claws:before,
.mn2 .wolverine .claws:before {
  top: 4px;
  width: 20px;
  right: 0;
}
.toggle-button .wolverine .claws:after,
.mn2 .wolverine .claws:after {
  top: -4px;
}
.toggle-button .wolverine.close .claws,
.mn2 .wolverine.close .claws {
  -webkit-transition: background .1s 0s;
  -webkit-transition-delay: ease;
  -moz-transition: background .1s 0s ease;
  -o-transition: background .1s 0s ease;
  transition: background .1s 0s ease;
  background: transparent!important;
}
.toggle-button .wolverine.close .claws:before,
.mn2 .wolverine.close .claws:before,
.toggle-button .wolverine.close .claws:after,
.mn2 .wolverine.close .claws:after {
  background: #fff;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.2s;
  -webkit-transition-delay: 0s,ease;
  -moz-transition: top 0.1s ease, -moz-transform 0.1s 0.2s ease;
  -o-transition: top 0.1s ease, -o-transform 0.1s 0.2s ease;
  transition: top .1s ease,transform .1s .2s ease;
  top: 0;
  width: 25px;
}
.toggle-button .wolverine.close .claws:before,
.mn2 .wolverine.close .claws:before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate(45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}
.toggle-button .wolverine.close .claws:after,
.mn2 .wolverine.close .claws:after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}
@media screen and (max-width: 1000px) {
  #nav_main {
    display: none;
  }
  #nav_main span.mobile_slug {
    display: block;
  }
  header#main_header #menu-trigger {
    display: block;
  }
  header#main_header {
    height: 50px;
  }
  body img.body_image {
    width: 100%;
  }
  .jPanelMenu-panel {
    background: url(img/bg.jpg) top right no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;
  }
  ul#jPanelMenu-menu {
    background: #183759;
    margin: 0;
    padding: 0;
    padding-top: 120px;
    width: 260px !important;
    right: 0;
  }
  ul#jPanelMenu-menu li#link_home {
    display: none;
  }
  ul#jPanelMenu-menu li.menu_item a {
    display: block;
    width: 100%;
    padding: 5px 25px;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 300;
  }
  ul#jPanelMenu-menu li.menu_item a i {
    display: none;
  }
  ul#jPanelMenu-menu li.menu_item ul.sub-menu {
    padding: 0;
    margin: 0;
    padding-left: 0px;
  }
  ul#jPanelMenu-menu li.menu_item ul.sub-menu li {
    position: relative;
    list-style: none;
  }
  ul#jPanelMenu-menu li.menu_item ul.sub-menu li a {
    padding-left: 45px;
  }
  ul#jPanelMenu-menu li.menu_item ul.sub-menu li:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-left: 5px solid #fff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    top: 16px;
    left: 27px;
  }
  ul#jPanelMenu-menu li.current-menu-item,
  ul#jPanelMenu-menu li a:hover {
    background: #0D1E31;
  }
  .fixed ul#jPanelMenu-menu {
    padding-top: 100px;
  }
}
