@header_height: 80px;

body {

  //overflow-x: hidden;
  margin: 0;
  padding: 0;
  padding-top: @header_height;
  width: 100%;
  height: auto;

  background:url(./img/bg.jpg) top right no-repeat !important; background-attachment:fixed !important;
  background-size: cover !important;

  div.wrap {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  div.clear {
    clear:both;
  }

  img.body_image {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    margin-top: -4px;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-family: 'graublausans-bold';
  }

  ul.body_image {
    overflow: auto;
    width: 100%;
    li.marken_image {
      position: relative;
      display: block;
      float: left;
      width: 40%;
      margin: 5%;
      img {
        max-width: 100%;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
}

html {
  box-sizing: border-box;
}

#main_header {
  position: fixed;
  display: block;
  top: 0;
  width: 100%;
  height: @header_height !important;
  background: #0D1E31;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.55);

  z-index: 200;

  #menu-trigger {
      display: none;
  }

  nav {

    position: relative;
    // position: absolute;
    // top: 1%;
    // left: 9%;
    ul {
      list-style: none;
      color: #fff;
      font-family: 'graublausans-bold';
      margin-left:8%;

      li {
        float: left;
        margin-right: 30px;
        text-transform: uppercase;
        letter-spacing: 2px;
        cursor: pointer;
        padding: 10px 20px;
        border: 2px solid transparent;
        font-size: 14px;

        &#link_home {
          img {
            width: 30px;
            height: auto;
            margin-top: -5px;
          }

          &.active {
            border: 2px solid transparent;
          }

          &:hover {
            border: 2px solid transparent;
          }
        }

        &.active {
          border: 2px solid #fff;
        }

        &:hover {
          border: 2px solid #fff;
        }

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

/* ------------------------- */
/*      MENU TOGGLE BUTTON
/* ------------------------- */

.toggle-button, .mn2 {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    .wolverine {
        display: inline-block;
        padding: 18px 10px;
        cursor: pointer;
        -webkit-user-select: none;
        opacity: .97;
        //background: #fff;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        .claws {
            position: relative;
            width: 25px;
            height: 3px;
            background: #fff;
            &:before, &:after {
                display: block;
                content: "";
                height: 3px;
                width: 25px;
                background: #fff;
                position: absolute;
                z-index: -1;
                -webkit-transform-origin: 50% 50%;
                -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-transition: top .1s .2s,-webkit-transform .2s ease;
                -webkit-transition-delay: ease,0s;
                -moz-transition: top .1s .2s ease,-moz-transform .2s ease;
                -o-transition: top .1s .2s ease,-o-transform .2s ease;
                transition: top .1s .2s ease,transform .2s ease;
            }
            &:before {
                top: 4px;
                width: 20px;
                right: 0;
            }
            &:after {
                top: -4px;
            }
        }
    &.close {
       //background: rgb(255,203,5) ;
       .claws {
                -webkit-transition: background .1s 0s;
                -webkit-transition-delay: ease;
                -moz-transition: background .1s 0s ease;
                -o-transition: background .1s 0s ease;
                transition: background .1s 0s ease;
                background: transparent!important;

                &:before, &:after {
                    background: #fff;
                    -webkit-transition: top .1s ease,-webkit-transform .1s .2s;
                    -webkit-transition-delay: 0s,ease;
                    -moz-transition: top .1s ease,-moz-transform .1s .2s ease;
                    -o-transition: top .1s ease,-o-transform .1s .2s ease;
                    transition: top .1s ease,transform .1s .2s ease;
                    top: 0;
                    width: 25px;
                }

                &:before {
                    -webkit-transform: rotate3d(0,0,1,45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate3d(0,0,1,45deg);
                }

                &:after {
                    -webkit-transform: rotate3d(0,0,1,-45deg);
                    -ms-transform: rotate(-45deg);
                    transform: rotate3d(0,0,1,-45deg);
                }
            }
     }
    }
}

@media screen and (max-width: 1000px)
{

    #nav_main {
        display: none;

				span.mobile_slug { display: block; }
    }

    header#main_header #menu-trigger {
        display: block;
    }

    header#main_header {
      height: @header_height - 30px;
    }

    body {
      img.body_image {
        width: 100%;
      }
    }

    //.jPanelMenu body { overflow: hidden; }

    .jPanelMenu-panel {
      background:url(./img/bg.jpg) top right no-repeat !important; background-attachment:fixed !important;
      background-size: cover !important;
    }

    ul#jPanelMenu-menu {
        background: lighten(#0D1E31, 10%);
        margin: 0;
        padding: 0;
        padding-top: 120px;
        width: 260px !important;
        right: 0;

        li#link_home {
          display: none;
        }

        li.menu_item {
            a {
                display: block;
                width: 100%;
                padding: 5px 25px;
                color: #fff;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 300;
								i { display: none; }
            }
            ul.sub-menu {
              padding: 0;
              margin: 0;
              padding-left: 0px;
              li {
                position: relative;
                list-style: none;
                a {
                  padding-left: 45px;
                }
              }
              li:before {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-left: 5px solid #fff;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                top: 16px;
                left: 27px;
              }
            }
        }
        li.current-menu-item, li a:hover  {
            background: #0D1E31;
        }
    }

    .fixed ul#jPanelMenu-menu {
        padding-top: 100px;
    }

}
